import React from 'react';
import styled from '@emotion/styled';

const ChevronWrapper = styled.div`
  margin-left: calc(var(--size-1) * 0.5);
  display: inline-block;
`;

const ChevronStyle = styled.div`
  display: inline-block;
  border-right: 2px solid
    ${(props) => (props.colour ? props.colour : 'var(--colour-white)')};
  border-bottom: 2px solid
    ${(props) => (props.colour ? props.colour : 'var(--colour-white)')};
  width: 10px;
  height: 10px;
  border-radius: 2px;
  transform: rotate(-45deg);
`;

const Chevron = ({ colour }) => {
  return (
    <ChevronWrapper>
      <ChevronStyle colour={colour} />
    </ChevronWrapper>
  );
};

export default Chevron;
