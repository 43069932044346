import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';

const PriceTable = () => {
  const priceTableStylesDesktop = css`
    display: none;
    @media (min-width: 768px) {
      /* display: block; */
    }

    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    th {
      background: #fff;
      display: none;
    }
    td,
    th {
      height: 53px;
    }
    td,
    th {
      border: 1px solid #ddd;
      padding: var(--size-4) var(--size-4);
      empty-cells: show;
      text-align: left;
    }
    th {
      padding: var(--size-8) var(--size-8);
    }
    td {
      font-size: calc(var(--size-1) * 1.125);
    }
    .table-value {
      font-size: var(--size-2);
      font-weight: 600;
    }
    td + td,
    th + th {
      text-align: center;
      display: none;
    }
    tr:nth-of-type(odd) {
      background: #fafafa;
    }
    tr:nth-of-type(even) {
      background: #fff;
    }
    td.default {
      display: table-cell;
    }
    .package-label {
      text-transform: uppercase;
      font-size: var(--size-2);
      font-weight: 600;
      letter-spacing: 2px;
    }
    .package-price {
      display: block;
      margin-top: calc(var(--size-1) * 0.5);
      font-size: var(--size-4);
    }
    .starter-heading {
      border-top: 8px solid #666;
      color: #666666;
    }
    .standard-heading {
      border-top: 8px solid #152749;
      color: #152749;
    }
    .sep {
      background: #f5f5f5;
      font-weight: bold;
    }
    .txt-l {
      font-size: 28px;
      font-weight: bold;
    }
    .txt-top {
      position: relative;
      top: -9px;
      left: -2px;
    }
    .included {
      color: #1f8d0e;
    }
    .hide {
      border: 0;
      background: none;
    }

    @media (min-width: 640px) {
      ul {
        display: none;
      }
      td,
      th {
        display: table-cell !important;
      }
      td,
      th {
        width: auto;
        min-width: 280px;
      }
      td + td,
      th + th {
        min-width: 220px;
        width: 50%;
      }
    }
  `;

  const priceTableStylesMobile = css`
    display: block;
    :first-of-type {
      margin-bottom: var(--size-8);
    }

    @media (min-width: 768px) {
      /* display: none; */
    }

    background: #fafafa;
    border-radius: 8px;
    overflow: hidden;

    @media (min-width: 900px) {
      /* display: none; */
    }
    .package-label {
      text-transform: uppercase;
      font-size: var(--size-2);
      font-weight: 600;
      letter-spacing: 2px;
    }
    .package-price {
      display: block;
      font-size: var(--size-4);
      font-weight: 700;
    }
    .starter-heading {
      text-align: center;
      padding: var(--size-2);
      background: #666;
      color: #fff;
    }
    .standard-heading {
      text-align: center;
      padding: var(--size-2);
      background: #152749;
      color: #fff;
    }

    .pricing-table {
      display: flex;
      flex-flow: column nowrap;
      font-size: calc(var(--size-1) * 1.125);
      padding: var(--size-4);
    }

    .row {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: var(--size-4);
      > div {
        width: 60%;
      }
    }

    .included {
      color: #1f8d0e;
    }
  `;

  const value = css`
    font-size: var(--size-2);
    font-weight: 600;
    margin-left: var(--size-2);
    flex: 0 0 40%;
    width: 40%;
  `;

  return (
    <>
      <table css={priceTableStylesDesktop}>
        <thead>
          <tr style={{ background: 'none' }}>
            <th className='hide'></th>
            {/* <th className='starter-heading'>
              <span className='package-label'>Starter</span>
              <br /> <span className='package-price'>&pound;6/month</span>
            </th> */}
            <th className='standard-heading'>
              <span className='package-label'>Standard</span>
              <br />
              <span className='package-price'>&pound;10/month</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Running sessions</td>
            {/* <td className='table-value'>
              <span className='included'>Included</span>
            </td> */}
            <td className='table-value'>
              <span className='included'>Included</span>
            </td>
          </tr>

          <tr>
            <td>Cycle sessions</td>
            {/* <td className='table-value'>
              <span className='included'>Included</span>
            </td> */}
            <td className='table-value'>
              <span className='included'>Included</span>
            </td>
          </tr>
          <tr>
            <td>Coached swimming sessions</td>
            {/* <td className='table-value'>
              <span>&pound;5</span>
            </td> */}
            <td className='table-value'>
              <span className='included'>Included</span>
            </td>
          </tr>
          <tr>
            <td>Coached running sessions</td>
            {/* <td className='table-value'>&pound;1</td> */}
            <td className='table-value'>
              <span className='included'>Included</span>
            </td>
          </tr>
          <tr>
            <td>
              <Link
                css={css`
                  font-weight: 600;
                `}
                to='/training-plans'
              >
                Personalised training plans
              </Link>
            </td>
            {/* <td className='table-value'>&pound;65/month</td> */}
            <td className='table-value'>
              <span>&pound;55/month</span>
            </td>
          </tr>

          <tr>
            <td>1 on 1 coached swimming with video&nbsp;analysis</td>
            {/* <td className='table-value'>&pound;30</td> */}
            <td className='table-value'>
              <span>&pound;20</span>
            </td>
          </tr>

          <tr>
            <td>Sports massage</td>
            {/* <td className='table-value'>
              <span>&pound;17</span>
            </td> */}
            <td className='table-value'>
              <span>&pound;17</span>
            </td>
          </tr>
        </tbody>
      </table>
      {/* <div css={priceTableStylesMobile}>
        <div className='starter-heading'>
          <span className='package-label'>Starter</span>
          <br /> <span className='package-price'>&pound;6/month</span>
        </div>

        <div className='pricing-table'>
          <div className='row'>
            <div>Running sessions</div>
            <div css={value} className='included'>
              Included
            </div>
          </div>
          <div className='row'>
            <div>Cycle sessions</div>
            <div css={value} className='included'>
              Included
            </div>
          </div>
          <div className='row'>
            <div>Coached swimming sessions</div>
            <div css={value}>&pound;5</div>
          </div>
          <div className='row'>
            <div>Coached running sessions</div>
            <div css={value}>&pound;1</div>
          </div>
          <div className='row'>
            <div>
              {' '}
              <Link
                css={css`
                  font-weight: 600;
                `}
                to='/training-plans'
              >
                Personalised training plans
              </Link>
            </div>
            <div css={value}>&pound;65/month</div>
          </div>
          <div className='row'>
            <div>1 on 1 coached swimming with video&nbsp;analysis</div>
            <div css={value}>&pound;30</div>
          </div>
          <div className='row'>
            <div>Sports massage</div>
            <div css={value}>&pound;17</div>
          </div>
        </div>
      </div> */}
      <div css={priceTableStylesMobile}>
        <div className='standard-heading'>
          <span className='package-label'>Standard</span>
          <span className='package-price'>&pound;10/month</span>
        </div>

        <div className='pricing-table'>
          <div className='row'>
            <div>Running sessions</div>
            <div css={value} className='included'>
              Included
            </div>
          </div>
          <div className='row'>
            <div>Cycle sessions</div>
            <div css={value} className='included'>
              Included
            </div>
          </div>
          <div className='row'>
            <div>Coached swimming sessions</div>
            <div css={value} className='included'>
              Included
            </div>
          </div>
          <div className='row'>
            <div>Coached running sessions</div>
            <div css={value} className='included'>
              Included
            </div>
          </div>
          <div className='row'>
            <div>
              <Link
                css={css`
                  font-weight: 600;
                `}
                to='/training-plans'
              >
                Personalised training plans
              </Link>
            </div>
            <div css={value}>&pound;55/month</div>
          </div>
          <div className='row'>
            <div>1 on 1 coached swimming with video&nbsp;analysis</div>
            <div css={value}>&pound;20</div>
          </div>
          <div className='row'>
            <div>Sports massage</div>
            <div css={value}>&pound;17</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceTable;
