import React from 'react';
import Img from 'gatsby-image';
import { css } from '@emotion/core';

const FounderQuote = ({ image, quote, name }) => {
  const rayQuoteStyles = css`
    display: flex;
    flex-flow: column wrap;
    align-items: center;

    @media (min-width: 768px) {
      flex-flow: row wrap;
    }
  `;

  const rayQuoteImage = css`
    max-width: 384px;
    width: 100%;
    flex: 0 0 100%;
    margin: 0 0 var(--size-4);
    @media (min-width: 768px) {
      flex: 0 0 40%;
      margin: 0;
    }
  `;

  const rayQuoteTextWrapper = css`
    flex: 0 0 100%;

    @media (min-width: 768px) {
      flex: 0 0 calc(100% - 40% - var(--size-8));
      margin-left: var(--size-8);
    }
  `;

  const rayQuoteText = css`
    font-size: var(--size-4);
    font-weight: 600;
    line-height: var(--line-height-heading);
    @media (min-width: 768px) {
      font-size: var(--size-5);
    }
  `;

  return (
    <div css={rayQuoteStyles}>
      <Img
        css={rayQuoteImage}
        fluid={{
          ...image.fluid,
          sizes: '(min-width: 800px) 400px, 200vw',
        }}
      />
      <div css={rayQuoteTextWrapper}>
        <p css={rayQuoteText}>{quote}</p>
        <div>
          <span
            css={css`
              font-size: var(--size-4);
              font-weight: 700;
              line-height: 1.25;
            `}
          >
            {name}
          </span>
          <br />
          <span
            css={css`
              font-size: var(--size-4);
              line-height: 1.25;
            `}
          >
            Founder and head coach
          </span>
        </div>
      </div>
    </div>
  );
};

export default FounderQuote;
